<template>
  <div>
    <v-card class="escalation-protocol">
      <v-card-title>
        <span>{{$t('t.EscalationProtocol')}}</span>
        <v-btn
          class="ml-4"
          :disabled="!isDirty"
          fab
          x-small
          color="success"
          @click.stop="save()"
        >
          <v-icon ref="check">{{$icon('i.Checked')}}</v-icon>
        </v-btn>
        <v-btn
          class="ml-2"
          :disabled="!isDirty"
          fab
          x-small
          color="warning"
          @click.stop="load()"
        >
          <v-icon>{{$icon('i.Undo')}}</v-icon>
        </v-btn>
      </v-card-title>
      <v-skeleton-loader
        type="image"
        class="risk"
        v-if="!settings"
      />
      <v-card-text v-else>
        <v-form>
          <ul class="responsive-grid">
            <v-radio-group v-model="settings.validationMode">
              <template v-slot:label>
                <span>{{$t('t.ValidationMode')}}</span>
              </template>
              <v-radio
                :label="$t('t.Manual')"
                :value="1"
              ></v-radio>
              <v-radio
                :label="$t('t.Automatic')"
                :value="0"
              ></v-radio>
            </v-radio-group>
            <v-text-field :label="$t('t.DunningSchedule')" />
            <v-switch
              dense
              :label="$t('t.GroupPostalDunningProposalsByWorkQueue')"
            />
            <v-switch
              dense
              :label="$t('t.GroupPostalDunningProposalsByCompany')"
            />
          </ul>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  comments: {
  },
  mounted () {
    this.load()
  },
  computed: {
    isDirty () {
      return !(JSON.stringify(this.settings) === JSON.stringify(this.initialSettings))
    }
  },
  data () {
    return {
      initialSettings: null,
      settings: null
    }
  },
  methods: {
    async load () {
      this.settings = { validationMode: 1 }
      this.initialSettings = Object.assign({}, this.settings)
      // this.showConfirmation = false
      // const r = await this.$http().get('/core/v6/settings/ecosystem-restrictions')
      // this.settings = r?.data
      // this.initialSettings = Object.assign({}, r?.data)
    },
    async save () {
      // await this.$http().post('/core/v6/settings/ecosystem-restrictions', this.settings)
      //   .then(() => {
      //     this.$store.dispatch('showSuccessSnackbar', this.$t('t.Snackbar.SaveConfirmation', { title: this.$t('t.EscalationProtocol') }))
      //     this.load()
      //   })
      //   .catch(e => this.$store.dispatch('showErrorSnackbar', e.response?.data?.message))
    }
  }
}
</script>

<style lang="stylus">
.responsive-grid
  display grid
  grid-template-columns repeat(auto-fill, minmax(15rem, auto))
  column-gap 1em
</style>
